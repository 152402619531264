







































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import isEmpty from 'lodash/isEmpty'
import {
  LoginParams,
  LoginResponse,
  LoginResponseSaved
} from '../types/api'

const LoginModule = namespace('login')

@Component
export default class LoginForm extends Vue {
  @LoginModule.Action('login') login!: (
    params: LoginParams
  ) => Promise<LoginResponse>
  @LoginModule.Getter('loginSaved') loginSaved!: LoginResponseSaved | undefined

  message = ''
  staffEmail = ''
  password = ''
  save = false

  get hasMessage() {
    return !isEmpty(this.message)
  }

  mounted() {
    if (!this.loginSaved) return
    const { staffEmail, password } = this.loginSaved
    this.staffEmail = staffEmail
    this.password = password
    this.save = true
  }

  resetPassword() {
    this.$emit('resetPassword')
  }

  handleLogin() {
    if (!this.staffEmail || !this.password) {
      this.message = '正しいログイン情報を入力してください'
      return
    }
    this.message = ''
    const params = {
      staffEmail: this.staffEmail,
      password: this.password,
      save: this.save
    }
    this.login(params)
      .then( (r) => {
        this.$emit('login', { data: r.data })
        const url = process.env.VUE_APP_BASE_URL_MYPAGE || 'http://localhost:8082/mypage/'
        window.location.href = url
      })
      .catch(e => {
        this.message = e
      })
  }
}
