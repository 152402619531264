











import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class Modal extends Vue {
  @Prop() private show!: boolean
  @Prop({ default: false }) private hideClose!: boolean

  handleClose() {
    this.$emit('close')
  }
}
