






import { Component, Vue } from 'vue-property-decorator'
import { setHeadTags, buildHeadTags } from '../../lib/headTagCreate'
import ResetPassword from '../components/ResetPassword.vue'

@Component({
  components: {
    ResetPassword
  },
})

export default class ResetPasswordView extends Vue {
  created() {
    setHeadTags(buildHeadTags.resetPassword())
  }
}
