export type CookieLoginData = {
  agent_code?: string
  password: string
  status: string
  save: string
  staffEmail: string
}

export type AppleAgentWebErrorDetailResponse = {
  code: ErrorType
  message: string
}

export type AppleAgentWebErrorDetail = {
  statusCode: number
  response: AppleAgentWebErrorDetailResponse
}

export type CookieLoginFlag = {
  status: string
}

export type ErrorSerttings = {
  [key: string]: ErrorSetting
}

export type ErrorSetting = {
  message: string
  code: ErrorType
  display: string
  statusCode?: number
  research?: boolean
}

export enum ErrorType {
  InternalServerError = 100,
  NotFound,
  GatewayTimeout,
  InvalidParameter,
  LoginAuthError,
  LoginDataNotFound,
  HotelDataNotFound,
  LocationDataNotFound,
  CityDataNotFound,
  HotelPlanNotFound,
  TieupNotFound,
  SessionNotFound,
  SharedSessionNotFound,
  SharedSessionInitializeError,
  SiteDataNotFound,
  PromiseCanceled,
  LandmarkDataNotFound,
  GeocoderResultNotFound
}

export enum CookieKey {
  LoginData = '__apw_ld__',
  AuthFlag = 'auth_flag_a', // for old Frontend,
  AuthData = 'auth_data_a', // for old Frontend,
  Ref = 'ref', // for Mypage,
  Session = 'JSESSIONID',
  ShowOldSite = 'no_redirect_v2'
}

export enum StorageKey {
  FavoriteHotels = '__apw_fvh__',
  SearchHistories = '__apw_sch__',
  HotelDetailHistory = '__apw_hdh__'
}

export enum Company {
  Name = 'アップルワールド',
  SiteName = 'APPLE WORLD NEXT'
}

export enum SiteId {
  Agt = 'AGT',
  Ant = 'ANT',
  Axc = 'AXC',
  Gal = 'GAL',
  Lnx = 'LNX'
}

export type Result<T> = T | Error

export enum Page {
  Top = 'Top',
  ResetPassword = 'ResetPassword',
  ResetPasswordDone = 'ResetPasswordDone',
  RegisterAgreement = 'RegisterAgreement',
  PrivacyTreatment = 'PrivacyTreatment',
  Legal = 'Legal',
  InfoTerms = 'InfoTerms',
  Privacy = 'Privacy',
  Device = 'Device',
  Contract = 'Contract',
  NotFound = 'NotFound',
}

export type CancelablePromise = {
  promise: Promise<any>
  cancel: () => void
}