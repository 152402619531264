import { MutationTree } from 'vuex'
import { LoginResponse } from '../../types/api'
import { LoginState } from '../types'

const mutations: MutationTree<LoginState> = {
  update: (state, response: LoginResponse) => {
    state.data = response
  },
  logout: state => {
    if (state.data?.data) state.data.data = undefined
  }
}

export default mutations
