














import { Component, Vue } from 'vue-property-decorator'
import {
  LoadLoginParams
} from './types/api'
import HeaderNav from './components/HeaderNav.vue'
import Footer from './components/Footer.vue'
import { namespace } from 'vuex-class'

const LoginModule = namespace('login')

@Component({
  components: {
    HeaderNav,
    Footer
  },
})

export default class App extends Vue {
  @LoginModule.Action('init') init!: (
    params: LoadLoginParams
  ) => Promise<void>
  showLoginModal = false

  async created() {
    await this.init({ siteId: 'SUB' })
  }

  handleOpenModal() {
    this.showLoginModal = true
  }
  handleCloseModal() {
    this.showLoginModal = false
  }
}
