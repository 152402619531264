import values from 'lodash/values'
import get from 'lodash/get'
import HttpStatus from 'http-status-codes'
import {
  ErrorSetting,
  AppleAgentWebErrorDetail,
  ErrorType,
  ErrorSerttings
} from '../types/app'

export class AppleAgentWebError extends Error {
  name = 'AppleAgentWebError'
  message: string
  detail: AppleAgentWebErrorDetail
  constructor(errorSetting: ErrorSetting) {
    super(errorSetting.message)
    const { message, statusCode, code } = errorSetting
    this.message = message
    this.detail = {
      statusCode: statusCode || HttpStatus.INTERNAL_SERVER_ERROR,
      response: { code, message }
    }
  }
}

export const ERROR_SETTINGS: ErrorSerttings = {
  INTERNAL_SERVER_ERROR: {
    message: 'internal server error',
    code: ErrorType.InternalServerError,
    display: 'システムエラーです'
  },
  NOT_FOUND: {
    message: 'not found',
    code: ErrorType.NotFound,
    display: 'お探しのページが見つかりませんでした。',
    statusCode: HttpStatus.NOT_FOUND
  },
  GATEWAY_TIMEOUT: {
    message: 'gateway timeout',
    code: ErrorType.GatewayTimeout,
    display: 'タイムアウトエラーです',
    statusCode: HttpStatus.GATEWAY_TIMEOUT
  },
  INVALID_PARAMETER: {
    message: 'invalid parameter',
    code: ErrorType.InvalidParameter,
    display: '無効なURLパラメータです',
    statusCode: HttpStatus.BAD_REQUEST
  },
  LOGIN_AUTH_ERROR: {
    message: 'login authentication error',
    code: ErrorType.LoginAuthError,
    display: '認証に失敗しました、入力内容をご確認ください',
    statusCode: HttpStatus.UNAUTHORIZED
  },
  LOGIN_DATA_NOT_FOUND: {
    message: 'login data not found',
    code: ErrorType.LoginDataNotFound,
    display: 'ログイン情報が見つかりませんでした',
    statusCode: HttpStatus.UNAUTHORIZED
  },
  HOTEL_DATA_NOT_FOUND: {
    message: 'hotel data not found',
    code: ErrorType.HotelDataNotFound,
    display: 'ホテル情報が見つかりませんでした',
    statusCode: HttpStatus.NOT_FOUND
  },
  LOCATION_DATA_NOT_FOUND: {
    message: 'locaton data not found',
    code: ErrorType.LocationDataNotFound,
    display: 'ロケーション情報が見つかりませんでした',
    statusCode: HttpStatus.NOT_FOUND
  },
  CITY_DATA_NOT_FOUND: {
    message: 'city data not found',
    code: ErrorType.CityDataNotFound,
    display: '都市情報が見つかりませんでした',
    statusCode: HttpStatus.NOT_FOUND
  },
  LANDMARK_DATA_NOT_FOUND: {
    message: 'landmark data not found',
    code: ErrorType.LandmarkDataNotFound,
    display: 'ランドマーク情報が見つかりませんでした',
    statusCode: HttpStatus.NOT_FOUND
  },
  GEOCODER_RESULT_NOT_FOUND: {
    message: 'geocoder result not found',
    code: ErrorType.GeocoderResultNotFound,
    display: '位置情報が取得できませんでした',
    statusCode: HttpStatus.NOT_FOUND,
    research: true
  },
  HOTEL_PLAN_NOT_FOUND: {
    message: 'hotel plan not found',
    code: ErrorType.HotelPlanNotFound,
    display: 'プラン情報が見つかりませんでした',
    statusCode: HttpStatus.NOT_FOUND
  },
  TIEUP_NOT_FOUND: {
    message: 'tieup data not found',
    code: ErrorType.TieupNotFound,
    display: 'サイト情報が見つかりませんでした',
    statusCode: HttpStatus.NOT_FOUND
  },
  SESSION_NOT_FOUND: {
    message: 'request session not found',
    code: ErrorType.SessionNotFound,
    display: 'システムエラーです',
    statusCode: HttpStatus.INTERNAL_SERVER_ERROR
  },
  SHARED_SESSION_NOT_FOUND: {
    message: 'shared session data not found',
    code: ErrorType.SharedSessionNotFound,
    display: 'ページの有効期限が切れました',
    statusCode: HttpStatus.NOT_FOUND
  },
  SHARED_SESSION_INITIALIZE_ERROR: {
    message: 'shared session initialize error',
    code: ErrorType.SharedSessionInitializeError,
    display: 'システムエラーです',
    statusCode: HttpStatus.INTERNAL_SERVER_ERROR
  },
  SITE_DATA_NOT_FOUND: {
    message: 'site data not found',
    code: ErrorType.SiteDataNotFound,
    display: 'サイト情報が見つかりませんでした',
    statusCode: HttpStatus.NOT_FOUND
  },
  PROMISE_CANCELED: {
    message: 'promise canceled',
    code: ErrorType.PromiseCanceled,
    display: 'リクエストが中断されました',
    statusCode: HttpStatus.INTERNAL_SERVER_ERROR
  }
}

export const generateError = (type: ErrorType): AppleAgentWebError => {
  const setting = getErrorSetting(type)
  return new AppleAgentWebError(setting)
}

export const getErrorSetting = (arg: ErrorType | Error): ErrorSetting => {
  if (arg instanceof Error) {
    const type = getErrorType(arg)
    return type ? getErrorSetting(type) : ERROR_SETTINGS.INTERNAL_SERVER_ERROR
  }
  return (
    values(ERROR_SETTINGS).find(setting => setting.code === arg) ||
    ERROR_SETTINGS.INTERNAL_SERVER_ERROR
  )
}

const getErrorType = (e: Error): ErrorType | undefined => {
  // AppleAgentWebError
  if (e instanceof AppleAgentWebError) return e.detail.response.code
  // Axios API error
  if (get(e, 'response.data.code')) return get(e, 'response.data.code')
  // Other
  if (get(e, 'response.status') === HttpStatus.GATEWAY_TIMEOUT)
    return ErrorType.GatewayTimeout
}
