










































































  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { namespace } from 'vuex-class'
  import { AxiosResponse } from 'axios'
  import { LoginResponseSaved, LoginResponseData } from '../types/api'
  import Modal from './common/Modal.vue'
  import LoginForm from './LoginForm.vue'

  const LoginModule = namespace('login')

  @Component({
    components: {
      Modal,
      LoginForm,
    },
  })

  export default class HeaderNav extends Vue {
    @Prop({ default: false }) private showLoginModal!: boolean
    @LoginModule.Getter('loginData') loginData?: LoginResponseData
    @LoginModule.Getter('loginSaved') loginSaved!: LoginResponseSaved | undefined
    @LoginModule.Action('logout') logout!: () => Promise<AxiosResponse<any>>

    get loginMail() {
      return this.loginData?.staff_email || ''
    }
    get loginAgentCode() {
      return this.loginData?.agentCode || ''
    }

    handleResetPassword() {
      this.$emit('closeModal')
      this.$router.push('/login/password')
    }
    handleOpen() {
      this.$emit('openModal')
    }
    handleClose() {
      this.$emit('closeModal')
    }
    handleLogout() {
      this.logout()
    }
    transitionMyPage() {
      const url = process.env.VUE_APP_BASE_URL_MYPAGE || 'http://localhost:8082/mypage/'
      window.location.href = url
    }
}
