

























































import { Component, Vue } from 'vue-property-decorator'
import isEmpty from 'lodash/isEmpty'
import { resetPassword } from '../lib/api'

@Component
export default class ResetPassword extends Vue {
  staffEmail = ''
  message = ''

  get hasMessage() {
    return !isEmpty(this.message)
  }

  async handleSubmit() {
    if (!this.staffEmail) {
      this.message = 'メールアドレスを入力してください。'
      return
    }
    const params = {
      staffEmail: this.staffEmail,
      siteId: 'SUB'
    }
    try {
      const result = await resetPassword(params)
      if (!result) {
        this.message ='エラーが発生しました。正しいメールアドレスが入力されているかご確認ください。'
        return
      }
      this.$router.push('/complete?from=reset')
    } catch (e) {
      this.message = `予期せぬエラー：${e}`
    }
  }
}
