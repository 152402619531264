






import { Component, Vue } from 'vue-property-decorator'
import { setHeadTags, buildHeadTags } from '../../lib/headTagCreate'
import PrivacyText from '../components/common/PrivacyText.vue'

@Component({
  components: {
    PrivacyText
  },
})

export default class PrivacyView extends Vue {
  created() {
    setHeadTags(buildHeadTags.privacyView())
  }
}
