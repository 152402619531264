enum Company {
    Name = 'アップルワールド',
    SiteName = 'APPLE WORLD NEXT'
  }

enum HeadTag {
  title = 'title',
  link = 'link',
  meta = 'meta'
}

type HeadTags = {
  title: string
  links: Link[]
  metas: Meta[]
}

type Link = {
  rel: string
  href: string | null
}

type Meta = {
  name?: string
  property?: string
  content: string | null
}

const titleSuffix = ` | ${Company.Name}(${Company.SiteName})`

const baseMetas: Meta[] = [
  { name: 'robots', content: 'noindex, nofollow' },
  { name: 'keyword', content: null },
  { name: 'description', content: null },
  { property: 'og:url', content: null },
  { property: 'og:type', content: null },
  { property: 'og:description', content: null },
  { property: 'og:title', content: null },
  { property: 'og:site_name', content: null },
  { property: 'og:image', content: null }
]

const mergeMetas = (metas: Meta[]) =>
  metas.reduce((memo, meta) => {
    let overrided = false
    const metas = memo.map(base => {
      if ((base.name && base.name === meta.name) || (base.property && base.property === meta.property)) {
        overrided = true
        return meta
      }
      return base
    })
    if (overrided) return metas
    return [...memo, meta]
  }, baseMetas)

export const setHeadTags = (headTags: HeadTags) => {
  if (!document) return
  const head = document.querySelector('head')
  if (!head) return
  // title
  const title = document.querySelector('title')
  if (title) {
    document.title = headTags.title + titleSuffix
  } else {
    const title = document.createElement(HeadTag.title)
    title.text = headTags.title + titleSuffix
    head.appendChild(title)
  }
  // link
  headTags.links.forEach(setting => {
    const link = head.querySelector(`[rel="${setting.rel}"]`)
    if (link) {
      setting.href !== null ? link.setAttribute('href', setting.href) : head.removeChild(link)
      return
    }
    if (setting.href) {
      const link = document.createElement(HeadTag.link)
      link.setAttribute('rel', setting.rel)
      link.setAttribute('href', setting.href)
      setting.href && head.appendChild(link)
    }
  })
  // meta
  mergeMetas(headTags.metas).forEach(setting => {
    const meta =
      head.querySelector(`[name="${setting.name}"]`) || head.querySelector(`[property="${setting.property}"]`)
    if (meta) {
      setting.content !== null ? meta.setAttribute('content', setting.content) : head.removeChild(meta)
      return
    }
    if (setting.content) {
      const meta = document.createElement(HeadTag.meta)
      if (setting.name) meta.setAttribute('name', setting.name)
      if (setting.property) meta.setAttribute('property', setting.property)
      meta.setAttribute('content', setting.content)
      setting.content && head.appendChild(meta)
    }
  })
}

export const buildHeadTags = {
  top: (): HeadTags => ({
    title: 'TOP',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  loginForm: (): HeadTags => ({
    title: 'ログイン',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  registerAgreement: (): HeadTags => ({
    title: '新規代理店登録申請同意',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  legal: (): HeadTags => ({
    title: '旅行業登録票・約款',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  terms: (): HeadTags => ({
    title: '利用規約',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  privacyView: (): HeadTags => ({
    title: 'プライバシーポリシー',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  device: (): HeadTags => ({
    title: '推奨環境',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  contract: (): HeadTags => ({
    title: '旅行サービス手配業務委託契約書',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  faqView: (): HeadTags => ({
    title: 'よくあるご質問',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  completeView: (): HeadTags => ({
    title: '本申込み送信完了',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  resetPassword: (): HeadTags => ({
    title: 'パスワード再発行',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  resetPasswordDoneView: (): HeadTags => ({
    title: 'パスワード再発行 完了',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  privacyTreatment: (): HeadTags => ({
    title: '個人情報の取扱いに関して',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  }),
  notFound: (): HeadTags => ({
    title: '指定されたページは見つかりません',
    links: [{ rel: 'canonical', href: location.origin + location.pathname }],
    metas: []
  })
}
