import { GetterTree } from 'vuex'
import { LoginState, RootState } from '../types'

const getters: GetterTree<LoginState, RootState> = {
  loggedIn: (state: LoginState) => {
    return !!state.data?.data
  },
  loginData: (state: LoginState) => {
    return state.data?.data
  },
  loginSaved: (state: LoginState) => {
    return state.data?.saved
  }
}

export default getters
