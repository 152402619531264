




















































































































































import { Component, Vue } from 'vue-property-decorator'
import { setHeadTags, buildHeadTags } from '../../lib/headTagCreate'
import { Page } from '../../types/app'

@Component
export default class RegisterAgreement extends Vue {
  created() {
    setHeadTags(buildHeadTags.registerAgreement())
  }

  prevStep() {
    this.$router.push({ name: Page.Top })
  }

  goForm() {
    document.location.href = 'https://form.jotform.com/232697608676069'
  }
}
