import { Module } from 'vuex'
import { LoginState, RootState } from '../types'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

export const login: Module<LoginState, RootState> = {
  namespaced: true,
  state: {
    data: null
  },
  getters,
  actions,
  mutations
}
