import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import store from '../store'
import { getErrorSetting } from '../../lib/errorHelper'
import { Page, ErrorType } from '../../types/app'
import Top from '../views/Top.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ResetPasswordDone from '../views/ResetPasswordDone.vue'
import RegisterAgreement from '../views/RegisterAgreement.vue'
import PrivacyTreatment from '../views/PrivacyTreatment.vue'
import Legal from '../views/Legal.vue'
import Terms from '../views/Terms.vue'
import Privacy from '../views/Privacy.vue'
import Device from '../views/Device.vue'
import Contract from '../views/Contract.vue'
import Complete from '../views/Complete.vue'
import Faq from '../views/Faq.vue'
import NotFound from '../views/NotFound.vue'
import { Position } from 'vue-router/types/router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: Page.Top,
    component: Top
  },
  {
    path: '/login/password',
    name: Page.ResetPassword,
    component: ResetPassword
  },
  {
    path: '/login/password/done',
    name: Page.ResetPasswordDone,
    component: ResetPasswordDone
  },
  {
    path: '/register/agreement',
    name: Page.RegisterAgreement,
    component: RegisterAgreement
  },
  {
    path: '/info/privacy/treatment',
    name: Page.PrivacyTreatment,
    component: PrivacyTreatment
  },
  {
    path: '/info/legal',
    name: Page.Legal,
    component: Legal
  },
  {
    path: '/info/terms',
    name: Page.InfoTerms,
    component: Terms
  },
  {
    path: '/info/privacy/policy',
    name: Page.Privacy,
    component: Privacy
  },
  {
    path: '/info/device',
    name: Page.Device,
    component: Device
  },
  {
    path: '/info/contract',
    name: Page.Contract,
    component: Contract
  },
  {
    path: '/complete',
    name: 'Complete',
    component: Complete
  },
  {
    path: '/service/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '*',
    name: Page.NotFound,
    component: NotFound
  },
]

const scrollBehavior = (to: Route, from: Route, savedPosition: void | Position) => {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

router.beforeEach((to, _from, next) => {
  if (to.name === Page.NotFound) {
    store.commit('app/setErrorCode', ErrorType.NotFound)
    return next()
  }
  new Promise<void>((resolve, reject) => {
    store.dispatch('login/init', { siteId: 'SUB' })
    resolve()
  })
    .then(() => {
      next()
    })
    .catch(e => {
      const error = getErrorSetting(e)
      store.commit('app/setErrorCode', error.code)
    })
})

export default router
