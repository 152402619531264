import axios from 'axios'
import {
  LoginParams,
  LoginResponse,
} from '@/types/api'
import { LoadLoginParams } from '../types/api'

export const login = async (params: LoginParams): Promise<LoginResponse> => {
  const result = await axios.post('/mypage/api/login', params)
  return result.data as LoginResponse
}

export const loadLogin = async (
  params: LoadLoginParams
): Promise<LoginResponse> => {
  const result = await axios.get('/mypage/api/login/data', { params })
  return result.data as LoginResponse
}

export const logout = async () => {
  return await axios.post('/mypage/api/logout')
}

export const resetPassword = async (params: { staffEmail: string, siteId: string }) => {
  try {
    const result = await axios.post('/mypage/api/resetPassword', params)
    return result.data
  } catch {
    return
  }
}
