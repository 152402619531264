






































































































import { Component, Vue } from 'vue-property-decorator'
import {
  LoginResponseData
} from '../types/api'
import { namespace } from 'vuex-class'

const LoginModule = namespace('login')

@Component
  export default class Footer extends Vue {
    @LoginModule.Getter('loginData') loginData?: LoginResponseData

    get loginMail() {
      return this.loginData?.staff_email || ''
    }
    get loginAgentCode() {
      return this.loginData?.agentCode || ''
    }
  }
