import { ActionTree } from 'vuex'
import { AxiosResponse } from 'axios'
import { RootState, LoginState } from '../types'
import { LoginParams, LoginResponse, LoadLoginParams } from '../../types/api'
import { login, logout, loadLogin } from '../../lib/api'

const actions: ActionTree<LoginState, RootState> = {
  login: ({ commit }, params: LoginParams): Promise<LoginResponse> => {
    return login(params).then(res => {
      commit('update', res)
      return res
    })
  },

  logout: ({ commit }): Promise<AxiosResponse> => {
    return logout().then(res => {
      commit('logout')
      return res
    })
  },

  init: ({ commit, state }, params: LoadLoginParams): Promise<void> => {
    if (state.data) return Promise.resolve()
    if (!params || !params.siteId)
      // return Promise.reject(generateError(ErrorType.InvalidParameter))
      return Promise.reject()
    return loadLogin(params)
      .then(res => {
        commit('update', res)
      })
      .catch(_e => {
        commit('update', {
          saved: undefined,
          data: undefined
        })
        console.log(_e)
      })
  }
}

export default actions
